@import url('https://fonts.cdnfonts.com/css/mosk');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono&family=Numans&display=swap');

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  cursor:crosshair;
}

body {
  font-family: 'Numans', sans-serif;
  padding: 0;
  margin: 0;

  color: rgb(230, 227, 216);
  background-color: rgb(23, 23, 23);
  transition: background-color 0.8s, color .3s;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2c2c2ce3;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(230, 227, 216);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #169873;
}

nav {
  position: fixed;
  width: 100%;
  text-align: center;
  transition: .5s;
  z-index: 1;
  top: 0px;
  left: 0px;

  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 5px );
}

nav li {
  display: inline-block;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
} 

nav a:hover {
  color: grey;
}

nav a {
  text-decoration: none;
  font-weight: bold;

  transition: .3s;
}

.bottom-button {
  position: fixed;
  right: 10px;
  top: 94%;
  background-color: #02496d;
  padding: 9px;
  color: ivory;
  border-radius: 50px;
  font-family: 'Numans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  transition: .2s ease-out;
}

button img {
  width: 20px;
  top: 0;
  left: 0;
  filter: invert(100%);
  transition: 1s;
} #color-mode {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  vertical-align: middle;
  top: 0;
  right: 0;
  cursor: pointer;

  padding: 15px;
  position: fixed;
  z-index: 2;
}

header {
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 800px;
}

.about-container {
  max-width: 55vw;
  min-width: none;
  margin-left: 80px;
  margin-top: 150px;
  line-height: 25px;
  transition: 1s;
  animation: 1s ease-in-out 0s 1 text;
  display: inline-block;
}

@keyframes text {
  0% {
    line-height: 10px;
    opacity: 0;
  } 
  100% {
    line-height: 25px;
    opacity: 1;
  }
}

header h1 {
  font-family: "Mosk", sans-serif;
  font-size: 6.5vw;
  margin: 0;
  padding: 20px;
  padding-left: 5px;
  
  display: inline-block;
  line-height: 85px;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(3px);
  }
}

.shake-letter:hover {
  display: inline-block;
  animation: 0.2s ease 0s 1 shake;
}

.scroll-line-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-right: 120px;
}

.scroll-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#scroll {
  display: block;
  margin: 0 auto;
  padding: 0;

  width: 100px;
  filter: invert(0%) sepia(3%) saturate(6311%) hue-rotate(23deg) brightness(131%) contrast(101%);
  transition: .4s ease-out;
}

#line {
  margin: 0 auto;
  display: block;
  padding: 0;

  width: 4px;
  height: 5px;
  max-height: 480px;
  background-color: ivory;
  border-radius: 50px;
}

#line2 {
  margin: 0 auto;
  display: block;
  padding: 0;

  width: 4px;
  height: 5px;
  max-height: 1200px;
  background-color: ivory;
  border-radius: 50px;
}

.scroll-line-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-right: 200px;
}

#table-title {
  font-family: "Mosk", sans-serif;

  display: block;
  margin: 0 auto;
  padding: 0;
  font-size: 35px;
  
  transition: .5s;
}

@media (max-width: 768px) {
  .scroll-line-container {
    flex-direction: column;
    align-items: center; /* Change to center alignment */
  }
}

.image-container {
  display: inline-block;
  width: 20vw;
  position: absolute;
  right: 12%;
  top: 25%;
  margin-left: 7vw;
} 

.circle {
  background: plum;
  width: 28vw;
  min-width: 20vw;
  height: 28vw;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 15s linear infinite;
}

.circle img {
  width: 110%;
}

@keyframes morph{
  0%, 100% {
  border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
} 

.dotted-blob { 
  position: inherit;
  filter: invert(50%) sepia(28%) saturate(573%) hue-rotate(353deg) brightness(93%) contrast(83%);
  transform: translate(-120px, -50px);
  z-index: -1;
  width: 650px;
  height: 650px;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M818.5 591Q717 682 644 792.5T469.5 816q-101.5-87-222-133.5T176 518q49-118 89-236.5T435 148q130-15 274 20.5T886.5 352q33.5 148-68 239Z%22%2F%3E%3C%2FclipPath%3E%3Cpattern id=%22b%22 patternUnits=%22userSpaceOnUse%22 width=%2225%22 height=%2225%22 viewBox=%220 0 100 100%22 fill=%22%23212121%22%3E%3Ccircle cx=%2250%22 cy=%2250%22 r=%2212.5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M818.5 591Q717 682 644 792.5T469.5 816q-101.5-87-222-133.5T176 518q49-118 89-236.5T435 148q130-15 274 20.5T886.5 352q33.5 148-68 239Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); 
}

.solid-blob {
  position: inherit;
  transform: translate(-80px, -50px);
  z-index: -1;
  width: 500px;
  height: 500px;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M880 620.5Q831 741 727.5 811T502 876q-122-5-211.5-82t-132-185.5q-42.5-108.5-26-236t115-236.5Q346 27 494 45.5T728 168q86 104 143.5 218t8.5 234.5Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23169873%22 d=%22M880 620.5Q831 741 727.5 811T502 876q-122-5-211.5-82t-132-185.5q-42.5-108.5-26-236t115-236.5Q346 27 494 45.5T728 168q86 104 143.5 218t8.5 234.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");  
}

.outline-blob { 
  position: inherit;
  transform: translate(0px, -30px);
  z-index: -1;
  width: 500px;
  height: 500px;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M911 630.5Q859 761 734 799t-246 74q-121 36-255.5-22.5T65 646Q32 500 86 369t164-215q110-84 237-45t252 83q125 44 174.5 176T911 630.5Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22none%22 stroke=%22%23c43c1e%22 stroke-width=%2229%22 d=%22M911 630.5Q859 761 734 799t-246 74q-121 36-255.5-22.5T65 646Q32 500 86 369t164-215q110-84 237-45t252 83q125 44 174.5 176T911 630.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}


/* institutions */

.table {
  padding-top: 450px;
  padding-bottom: 150px;
  padding-left: 60px;
  display: flex;
  justify-content: center;
  align-items:  flex-start;
  height: auto;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  box-sizing: border-box;

  opacity: 0;
  transform: translateX(-60px);
  transition: opacity .3s ease-out, transform .1s ease-in;
}

.table.animate {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 1s ease, transform 1s ease;
}

.table h4 {
  font-family: "Mosk", sans-serif;
  font-size: 18px;
  margin-left: -100px;
}

.table table {
  width: 100%;
  border-collapse: collapse;
}

.table td {
  padding: 15px;
  transition: .5s;
} .table img:hover {
  width: 70px;
  height: 70px;
}

.table img {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  padding-right: 10px;
  transition: .5s ease;
} .table img:hover {
  width: 65px;
  height: 65px;
}


/* publications */

.publications {
  margin-bottom: 50px;
  padding-bottom: 150px;
  opacity: 0;
  transition-delay: .2s;
  transition-duration: .8s;
  background-color: rgb(23, 23, 23);
  transform-style: preserve-3d;
  overflow-x: hidden;
} .publications h1 {
  text-align: center;
  font-family: "Mosk", sans-serif;
  font-size: 35px;
  color: ivory;
  padding-top: 30px;
} .publications.animate {
  opacity: 1;
  background-color: #02496d;
}

#publications-container {
	box-sizing: border-box;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Pontano Sans', sans-serif;
  font-size: calc(0.65em + .05vw);
  
}

:root{
  --perspective: 1000px;
  --link-color:#010790;
}

.book-left, .book-right {
  display: inline-block;
  position: relative;
  width: 200px;
	height: 275px;
  margin: 0 auto;
	margin-top: 5rem;

  transition: 1s ease;
}

#book-1 {
  height: 260px;
  width: 190px;
} #book-2 {
  height: 290px;
} #book-3 {
  height: 250px;
} #book-4 {
  height: 270px;
} #book-5 {
  height: 245px;
} #book-6 {
  height: 285px;
}

.cover {
	position: absolute;
  width:100%;
	z-index:100;
  transition:transform .5s;
  transform: translateX(0);
  transform-origin: left center;
  backface-visibility: hidden;
}

.description{
  position: absolute;
  left:0;
  top:0;
  width:calc(inherit -20px);
  height:inherit;
	border: solid 1px grey;
	background:white;
  color: black;
  font-weight: bold;
	transition: all .5s;
	padding: 5px;
  padding-top: 0px;
  z-index:1;
	font-size: 1vw;
  font-family: 'Numans', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: center;
  box-shadow: 5px 5px 20px rgb(0, 0, 0);
} .description h2 {
  text-transform: uppercase;
  line-height: 13px;
  font-size: 1vw;
  font-weight:bolder;
  font-family: "Mosk", sans-serif;
}

.description::-webkit-scrollbar {
  width: 5px;
}

.description::-webkit-scrollbar-track {
  background-color: white;
}

.description::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 50px;
}

.book-left:hover {
  cursor: pointer;
  transform: translateX(-20px);
}

.book-right:hover {
  cursor: pointer;
  transform: translateX(-30px);
}

.book-left:hover .cover {
  transform: perspective(var(--perspective)) rotateY(-80deg);
  transition: transform 0.5s ease; /* Add transition to transform property */
}

.book-left:hover .description {
  transform: translateX(20%);
  transition: transform 0.5s ease; /* Add transition to transform property */
}

.book-right:hover .cover {
  transform: perspective(var(--perspective)) rotateY(-80deg);
  transition: transform 0.5s ease; /* Add transition to transform property */
}

.book-right:hover .description {
  transform: translateX(20%);
  transition: transform 0.5s ease; /* Add transition to transform property */
}


/* contact */

#contact {
  display: flex;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  left: 0;
  bottom: 0;
  background-color: rgb(12, 12, 12);
}

.contact-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#contact h1 {
  font-size: 90px;
  color: white;
  font-family: Mosk, sans-serif;
  
  margin-right: -600px;
  margin-top: -60px;
}

#contact p {
  font-size: 15px;
  color: white;
  padding: 5px;
  text-align: left;
  line-height: 25px;

  margin-right: -550px;
  margin-top: -20px;
}

#contact form {
  text-align: left;
  margin: 200px;
  margin-top: 250px;
}

#name,
#email,
textarea {
  padding: 5px;
  margin-bottom: 50px;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
  border-bottom: 1px solid white;
  color: white;
}

textarea {
  max-width: 50vh;
  height: 18px;
  min-height: 18px;
  max-height: 200px;

}

#submit {
  background-color: rgb(255, 255, 255);
  padding: 15px;
  width: 50vh;
  border: none;
  border-radius: 1px;
  text-transform: uppercase;
  font-weight: 800;
  transition: background-color 0.2s ease, border-radius 0.6s ease;

  transform: translateY(50px);
}

#submit:hover {
  background-color: #b93d1f;
  border-radius: 10px;
}

#submit:active{
  background-color: white;
}

#contact label {
  position: absolute;
  color: white;
  padding: 5px;
  pointer-events: none;
  z-index: 1;
  transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  transform-origin: top left;
}

/* presentations */

#presentations {
  padding: 200px;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-top: 150px;
}

.presentations-section h2 {
  font-family: Mosk, sans-serif;
  font-size: 5vh;
  text-decoration: underline;
}

.presentations-section ul {
  font-size: 2vh;
  list-style-type: none;
  line-height: 3;
  margin-left: -25px;
  white-space: nowrap;
}

/* courses */

#courses {
  padding: 200px;
  padding-top: 100px;
}

.courses-section h4 {
  font-size: 2.2vh;
  font-family: Mosk, sans-serif;
}

.courses-section h2 {
  font-family: Mosk, sans-serif;
  font-size: 5vh;
  text-decoration: underline;
  margin-bottom: -10px;
}

.courses-section ul {
  font-size: 2vh;
  list-style-type: none;
  line-height: 2;
  margin-left: -20px;
  white-space: nowrap;
}

/* gallery */

.gallery-column {
  display: flex;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 50%;
  margin-top: 40vh;
  text-align: center;
  margin-left: -10%;
}

.gallery-section img {
  width: 95%;
  border: solid #169873 10px;
  
  -webkit-box-shadow: 10px 10px 25px 8px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px 8px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px 8px rgba(0,0,0,0.75);
}

#gallery-1 {
  margin-top: 200vh;
  border: solid #169873 10px;
}

#presentations-1 {
  border: solid #c43c1e 10px;
}