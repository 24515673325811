/* Styles for screens with a maximum width of 768px (typical for small devices) */
@media (max-width: 768px) {
    body {
      font-size: 16px;
      overflow-x: hidden; /* Example: Adjust the base font size for better readability */
    }
  
    /* Navigation bar adjustments */
    nav {
      font-size: 14px; /* Reduce font size for navigation links */
    }
  
    nav li {
      padding: 5px 8px; /* Adjust padding around navigation links */
    }
  
    /* Header adjustments */
    .about-container {
      max-width: 90vw; /* Increase the maximum width of the content container */
      margin-left: 5%; /* Adjust the left margin for the content container */
    }
  
    header h1 {
      font-size: 5vw; /* Reduce the header font size for smaller screens */
    }
  
    /* Image container adjustments */
    .image-container {
      display: none; /* Hide the image container on small screens */
    }
  
    /* Presentations section adjustments */
    .presentations-section ul {
      font-size: 1.3vh; /* Reduce font size for presentation items */
    }
  
    /* Courses section adjustments */
    .courses-section h4 {
      font-size: 1.5vh; /* Reduce font size for course titles */
    }
  
    .courses-section ul {
      font-size: 1.3vh; /* Reduce font size for course items */
    }
  
    /* Footer adjustments */
    #contact h1 {
      font-size: 40px; /* Reduce font size for the contact header */
      margin-right: 0; /* Reset margin-right for the contact header */
      margin-top: 0; /* Reset margin-top for the contact header */
    }
  
    #contact p {
      font-size: 14px; /* Reduce font size for contact information */
      margin-right: 0; /* Reset margin-right for contact information */
      margin-top: 0; /* Reset margin-top for contact information */
    }
  
    #contact form {
      margin: 20px; /* Adjust margin for the contact form */
      margin-top: 10px; /* Adjust margin-top for the contact form */
    }
  
    /* Publications section adjustments (if needed) */
    /* Add your responsive CSS rules for the Publications section here */
  }
  